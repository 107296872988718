import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useRequestTopupMutation } from "../../../store";
import { toast } from "react-toastify";
import "./css/requestTopup.css";
import useToastSpinner from "../../../hooks/useToastSpinner";

const RequestTopUpComponent = () => {
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const [moneyType, setMoneyType] = useState(client?.DefaultPayment || "Bank");
  const token = useSelector((state) => state.user.token);
  const balance = useSelector((state) => state.user.balance);
  const { toastSpinner } = useToastSpinner();

  const [requestTopup] = useRequestTopupMutation();

  const handleNoteChange = (e) => setNote(e.target.value);
  const handleMoneyTypeChange = (e) => setMoneyType(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (parseFloat(amount) > balance) {
      toast.error("Insufficient balance.");
      return;
    }

    try {
      setIsSubmitting(true);

      // Use toastSpinner for handling requestTopup
      const res = await toastSpinner(
        requestTopup({
          clientID: client?.ID,
          moneyType,
          amount,
          note,
          token,
        }),
        "Requesting top-up...",
        "Top-up request successful.",
        "Failed to request top-up."
      );

      if (res?.data) {
        setAmount("");
        setNote("");
      } else {
        // Additional error handling
        toast.error(
          res.error?.data?.message || "Failed to process the request."
        );
      }
    } catch (error) {
      console.error("Unexpected error during top-up request:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const topUpOptions = useMemo(() => {
    if (
      client?.CardStatus === "Active" ||
      client?.CardStatus === "Card Ordered"
    ) {
      return (
        <>
          <option value="Bank">Bank Pay out</option>
          <option value="P.P. Card">Prepaid Card Top-Up</option>
        </>
      );
    }
    return <option value="Bank">Bank Pay out</option>;
  }, [client?.CardStatus]);

  return (
    <section>
      <div>
        <h2 className="text-5xl font-bold text-white mb-5">Request a Top-Up</h2>
        <p className="text-white text-lg font-bold mb-7">
          Current Balance: <span className="font-semibold">£ {balance}</span>
        </p>
        <form
          className="bg-white py-7 px-5 text-[#252525]"
          onSubmit={handleSubmit}
        >
          <div className="w-8/12">
            <div className="grid grid-cols-2 gap-6 mb-9">
              {/* Amount */}
              <div>
                <div>
                  <label htmlFor="amount" className="block font-medium mb-3">
                    Donation Amount
                  </label>
                  <input
                    id="amount"
                    className="w-full rounded border border-borderColor3 p-3 focus:ring-blue-500 focus:border-blue-500"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>

              {/* Pay out method */}
              <div>
                <label
                  htmlFor="payOutMethod"
                  className="block font-medium mb-3"
                >
                  Pay out method
                </label>
                <select
                  id="moneyType"
                  className="w-full rounded border border-borderColor3 p-3 focus:ring-blue-500 focus:border-blue-500"
                  value={moneyType}
                  onChange={handleMoneyTypeChange}
                >
                  {topUpOptions}
                </select>
              </div>
            </div>

            {/* Notes */}
            <div className="mb-6">
              <label htmlFor="notes" className="block font-medium mb-3">
                Notes
              </label>
              <textarea
                id="notes"
                value={note}
                onChange={handleNoteChange}
                rows="4"
                className="w-full border border-borderColor3 rounded p-3 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter additional notes here..."
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-[200px] h-[50px] text-lg bg-primary text-white font-bold py-3 rounded hover:bg-blue-800 transition"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default RequestTopUpComponent;
