import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Header = () => {
  const token = useSelector((state) => state.user.token); // Token to check if user is logged in
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <nav className="px-24 h-20 mt-8 relative z-50">
      <div
        className={`${
          location.pathname === "/contact-form" ||
          location.pathname === "/contact-us"
            ? "bg-[#D9D9D9]"
            : "bg-white"
        } rounded-[40px] px-5 flex justify-between items-center`}
      >
        <Link to="/">
          <img src="/logo.png" alt="act" className="h-full w-40" />
        </Link>
        <ul className="flex font-bold gap-11 text-secondary">
          {[
            { label: "Home", to: "/" },
            { label: "About us", to: "/about-us" },
            { label: "Open an account", to: "/contact-form" },
            { label: "Contact us", to: "/contact-us" },
          ].map((item) => (
            <li key={item.label} className="relative group">
              <Link
                to={item.to}
                className="relative pb-2 hover:text-primary transition-colors duration-200"
              >
                {item.label}
                {/* Underline effect */}
                <span className="absolute left-0 bottom-0 w-full h-[2px] bg-secondary scale-x-0 origin-left transition-transform duration-300 ease-in-out group-hover:scale-x-100"></span>
              </Link>
            </li>
          ))}
        </ul>

        {/* Conditionally render Login button or Profile icon */}
        {token ? (
          <button
            onClick={() => navigate("/account/account-details")}
            className="h-12 w-12 flex items-center justify-center rounded-full bg-gray-100 hover:bg-gray-300 transition-all"
          >
            <img
              src="/icons/sidebar/Account.png" // Replace with your profile icon path
              alt="Profile"
              className="w-8 h-8 rounded-full"
            />
          </button>
        ) : (
          <button
            onClick={() => navigate("/login")}
            className="py-2 px-5 rounded-[20px] flex text-xl gap-2 items-center font-semibold text-white"
            style={{
              background:
                "linear-gradient(108deg, #2A3577 43.58%, #6ECAC9 105.3%)",
            }}
          >
            Login <img src="/icons/SortDown.png" alt="" />
          </button>
        )}
      </div>
    </nav>
  );
};

export default Header;

// import React from "react";
// import { useSelector } from "react-redux";
// import { Link, useNavigate, useLocation } from "react-router-dom";

// const Header = () => {
//   const selectedClient = useSelector((state) => state.user.selectedClient);
//   const client = useSelector((state) => state.user.clients[selectedClient]);
//   const token = useSelector((state) => state.user.token);
//   const navigate = useNavigate();
//   const location = useLocation();
//   return (
//     <nav className="px-24 h-20 mt-8 relative z-50">
//       <div
//         className={`${
//           location.pathname === "/contact-form" ||
//           location.pathname === "/contact-us"
//             ? "bg-[#D9D9D9]"
//             : "bg-white"
//         } rounded-[40px] px-5 flex justify-between items-center`}
//       >
//         <Link to="/">
//           <img src="/logo.png" alt="act" className="h-full w-40" />
//         </Link>
//         <ul className="flex font-bold gap-11 text-secondary">
//           {[
//             { label: "Home", to: "/" },
//             { label: "About us", to: "/about-us" },
//             { label: "Open an account", to: "/contact-form" },
//             { label: "Contact us", to: "/contact-us" },
//           ].map((item) => (
//             <li key={item.label} className="relative group">
//               <Link
//                 to={item.to}
//                 className="relative pb-2 hover:text-primary transition-colors duration-200"
//               >
//                 {item.label}
//                 {/* Underline effect */}
//                 <span className="absolute left-0 bottom-0 w-full h-[2px] bg-secondary scale-x-0 origin-left transition-transform duration-300 ease-in-out group-hover:scale-x-100"></span>
//               </Link>
//             </li>
//           ))}
//         </ul>

//         <button
//           onClick={() => navigate("/login")}
//           className="py-2 px-5 rounded-[20px] flex text-xl gap-2 items-center font-semibold text-white"
//           style={{
//             background:
//               "linear-gradient(108deg, #2A3577 43.58%, #6ECAC9 105.3%)",
//           }}
//         >
//           Login <img src="/icons/SortDown.png" alt="" />
//         </button>
//       </div>
//     </nav>
//   );
// };

// export default Header;
