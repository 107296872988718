/* eslint-disable react/style-prop-object */
import React from "react";

const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="16"
      height="16"
      viewBox="0 0 90 90"
    >
      <defs></defs>
      <g
        style={{
          stroke: "none",
          strokeWidth: 0,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          fill: "none",
          fillRule: "nonzero",
          opacity: 1,
          cursor: "pointer",
        }}
      >
        <path
          d="M 46.969 0.896 c -1.041 -1.194 -2.897 -1.194 -3.937 0 L 13.299 35.011 c -0.932 1.072 -0.171 2.743 1.25 2.743 h 14.249 V 88.09 c 0 1.055 0.855 1.91 1.91 1.91 h 28.584 c 1.055 0 1.91 -0.855 1.91 -1.91 V 37.754 h 14.249 c 1.421 0 2.182 -1.671 1.25 -2.743 L 46.969 0.896 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(255, 191, 0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
        />
      </g>
    </svg>
  );
};

export default Arrow;
