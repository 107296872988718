export default function calculateTransactionType(PaidIn_PaidOut, isHold) {
  // Combine PaidIn_PaidOut and isHold into a string for the switch statement
  const key = `${PaidIn_PaidOut}_${isHold}`;
  // Transaction type determination
  let transactionType;
  switch (key) {
    case "1_false":
      transactionType = "Paid In";
      break;
    case "-1_false":
      transactionType = "Paid Out";
      break;
    case "-1_true":
      transactionType = "Pending Paid Out";
      break;
    case "2_false":
      transactionType = "Paid Out";
      break;
    case "-2_true":
      transactionType = "Pending Debit";
      break;
    case "-2_false":
      transactionType = "Debit";
      break;
    case "-3_false":
      transactionType = "Fees";
      break;
    case "4_false":
      transactionType = "Int. Received";
      break;
    case "-4_true":
      transactionType = "Pending Int. Send";
      break;
    case "-4_false":
      transactionType = "Int. Send";
      break;
    case "5_false":
      transactionType = "Int. Received";
      break;
    default:
      transactionType = "Unknown";
  }
  return transactionType;
}

// const columns = [
//   { label: "Date", key: "Date" },
//   { label: "Transaction type", key: "TransactionType" },
//   { label: "Amount", key: "Amount" },
//   { label: "Money type", key: "MoneyType" },
//   { label: "Fee", key: "Fee" },
//   { label: "Total", key: "Total" },
//   { label: "Note", key: "Note" },
// ];

// const [filters, setFilters] = useState(
//   columns.reduce((acc, col) => {
//     acc[col.key] = { operator: "equals", value: "" };
//     return acc;
//   }, {})
// );
// const [filteredData, setFilteredData] = useState(data);

// import React from "react";

// const FilterOptions = ({
//   setShowFilterPopover,
//   setShowAdvancedFilters,
//   clearFilters,
// }) => {
//   const handleFilterChange = (key, field, value) => {
//     setFilters((prev) => ({
//       ...prev,
//       [key]: { ...prev[key], [field]: value },
//     }));
//   };

//   return (
//     <div className="w-full right-10 mt-10 bg-white shadow-lg rounded-lg p-3 border border-gray-200">
//       <h3 className="text-gray-700 font-bold mb-2">Filter Options</h3>
//       <div className="grid grid-cols-2 gap-4">
//         {Object.keys(filters).map((key) => {
//           const column = columns.find((col) => col.key === key);

//           return (
//             <div key={key} className="px-4 flex items-end space-y-2">
//               <div className="flex-1">
//                 <label className="block text-gray-500 text-sm">
//                   {column?.label}
//                 </label>

//                 {/* For Date field */}
//                 {key === "Date" ? (
//                   <div
//                     className={`flex ${
//                       filters[key].operator === "range"
//                         ? "flex-col"
//                         : "flex-row"
//                     }`}
//                   >
//                     <select
//                       className="border w-full flex-1 rounded-md px-3 py-2 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
//                       value={filters[key].operator}
//                       onChange={(e) =>
//                         handleFilterChange(key, "operator", e.target.value)
//                       }
//                     >
//                       <option value="equals">Equals</option>
//                       <option value="range">Date Range</option>
//                     </select>

//                     {filters[key].operator === "range" ? (
//                       <div className="flex justify-between mt-2">
//                         <input
//                           className="border flex-1 h-10 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                           type="date"
//                           value={filters[key].value.startDate || ""}
//                           onChange={(e) =>
//                             handleFilterChange(key, "value", {
//                               ...filters[key].value,
//                               startDate: e.target.value,
//                             })
//                           }
//                         />
//                         <span className="mx-2">to</span>
//                         <input
//                           className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                           type="date"
//                           value={filters[key].value.endDate || ""}
//                           onChange={(e) =>
//                             handleFilterChange(key, "value", {
//                               ...filters[key].value,
//                               endDate: e.target.value,
//                             })
//                           }
//                         />
//                       </div>
//                     ) : (
//                       <input
//                         className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                         type="date"
//                         value={filters[key].value}
//                         onChange={(e) =>
//                           handleFilterChange(key, "value", e.target.value)
//                         }
//                       />
//                     )}
//                   </div>
//                 ) : key === "TransactionType" ||
//                   key === "MoneyType" ||
//                   key === "Note" ? (
//                   // For TransactionType, MoneyType, Note fields
//                   <div className="flex">
//                     <select
//                       className="border w-full flex-1 rounded-md px-3 py-2 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
//                       value={filters[key].operator}
//                       onChange={(e) =>
//                         handleFilterChange(key, "operator", e.target.value)
//                       }
//                     >
//                       <option value="equals">Equals</option>
//                       <option value="not-equals">Not Equals</option>
//                     </select>
//                     <input
//                       className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                       type="text"
//                       value={filters[key].value}
//                       onChange={(e) =>
//                         handleFilterChange(key, "value", e.target.value)
//                       }
//                     />
//                   </div>
//                 ) : (
//                   // For other fields (Amount, Total, etc.)
//                   <div className="flex">
//                     <select
//                       className="border flex-1 w-full rounded-md px-3 py-2 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
//                       value={filters[key].operator}
//                       onChange={(e) =>
//                         handleFilterChange(key, "operator", e.target.value)
//                       }
//                     >
//                       <option value="equals">Equals</option>
//                       <option value="not-equals">Not Equals</option>
//                       <option value="greater-than">Greater Than</option>
//                       <option value="less-than">Less Than</option>
//                     </select>
//                     <input
//                       className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                       type="text"
//                       value={filters[key].value}
//                       onChange={(e) =>
//                         handleFilterChange(key, "value", e.target.value)
//                       }
//                     />
//                   </div>
//                 )}
//               </div>
//             </div>
//           );
//         })}
//       </div>
//       <div className="mt-4 gap-3 flex justify-end">
//         <button
//           className="bg-[#FB2727] text-white px-4 py-2 rounded-md hover:bg-[#f06969]"
//           onClick={() => {
//             setShowFilterPopover(false);
//             setShowAdvancedFilters(false);
//             clearFilters();
//           }}
//         >
//           Clear Filters
//         </button>
//         <button
//           className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
//           onClick={() => {
//             setShowFilterPopover(false);
//             setShowAdvancedFilters(false);
//           }}
//         >
//           Apply
//         </button>
//       </div>
//     </div>
//   );
// };

// export default FilterOptions;
