import React, { useCallback, useState } from "react";

import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { useSignupByPasswordMutation, googleClientID } from "../store";
import { sinupSchema } from "../Validations/SignupValidations";
import Loader from "../components/shared/Loader";
import useToastSpinner from "../hooks/useToastSpinner";
import GoogleLoginBtn from "../components/button/GoogleLoginBtn";

const Signup = () => {
  const [isAgree, setIsAgree] = useState(false);
  const { toastSpinner } = useToastSpinner();
  const [SignupByPassword, { isLoading }] = useSignupByPasswordMutation();
  // Handlers
  const onSubmit = useCallback(
    async ({ email, password }, actions) => {
      try {
        // Use toastSpinner to handle loading, success, and error messages
        await toastSpinner(
          SignupByPassword({ email, password }),
          "Processing your request...",
          "Signup successful!",
          "We couldn't find an A.C.T. account with the provided email address."
        );

        // Reset the form if the operation was successful
        actions.resetForm();
      } catch (error) {
        console.error("Error during signup:", error);

        // Optionally handle the error further
        actions.setErrors({
          email:
            "There was an issue with the signup process. Please try again.",
        });
      }
    },
    [SignupByPassword, toastSpinner]
  );

  // const onSubmit = useCallback(
  //   async ({ email, password }, actions) => {
  //     await toastSpinner(
  //       SignupByPassword({ email, password }),
  //       "Sending invoice...",
  //       "Email has been sent successfully.",
  //       "There was a server-side error while sending the invoice!"
  //     );
  //     actions.resetForm();
  //   },
  //   [SignupByPassword, toastSpinner]
  // );

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: sinupSchema,
    onSubmit,
  });
  if (isLoading) {
    return <Loader />;
  }
  return (
    <section className="flex justify-center items-center">
      <GoogleOAuthProvider clientId={googleClientID}>
        <div
          className="flex items-center w-10/12 justify-center min-h-screen mt-20"
          style={{
            backgroundImage: "url('/bglogo.png')",
            // backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <form
            onSubmit={handleSubmit}
            className="w-full max-w-xl px-8 py-10 rounded-lg relative text-center"
          >
            <h1 className="text-5xl font-bold text-lGree mb-5">
              Signup for online access to your ACT account
            </h1>
            {/* Google Login Button */}
            <GoogleLoginBtn />

            <div className="my-9 flex justify-center items-center gap-2">
              <div className="w-16 bg-[#B1B1B1] h-[1px]"></div>
              <p className="text-[#1B1B1B] text-lg">or</p>
              <div className="w-16 bg-[#B1B1B1] h-[1px]"></div>
            </div>
            {/* Email Input */}
            <div className="mb-9">
              <input
                type="email"
                id="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Email"
                className="w-full px-4 py-3 border border-[#B1B1B1] rounded-[14px] focus:outline-none focus:ring-2 focus:ring-[#1F2977] text-[#1F2977]"
              />
            </div>

            {/* Password Input */}
            <div className="mb-9">
              <input
                label="Password"
                id="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                placeholder="Password"
                className="w-full px-4 py-3 border border-[#B1B1B1] rounded-[14px] focus:outline-none focus:ring-2 focus:ring-[#1F2977]"
              />
            </div>
            {/* Password Input */}
            <div className="mb-5">
              <input
                id="confirmPassword"
                value={values.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                placeholder="Re-enter password"
                className="w-full px-4 py-3 border border-[#B1B1B1] rounded-[14px] focus:outline-none focus:ring-2 focus:ring-[#1F2977]"
              />
            </div>

            {/* Forgot Password */}
            <div className="flex items-center mb-5">
              <input
                type="checkbox"
                id="agreement"
                className="w-4 h-4 cursor-pointer text-[#1F2977] border border-gray-300 rounded focus:ring-0"
                onClick={() => setIsAgree(!isAgree)}
              />
              <label
                htmlFor="agreement"
                className="ml-2 text-[#B1B1B1] text-sm"
              >
                I accept all terms & condition
              </label>
            </div>

            {/* Login Button */}
            <button
              disabled={!isAgree}
              type="submit"
              className="w-full py-3 rounded-[27px] bg-lGree text-white text-lg font-semibold hover:bg-lGree"
            >
              Signup
            </button>

            {/* Sign Up Link */}
            <p className="mt-4 text-gray-500 text-sm">
              Already have an account?{" "}
              <Link
                to="/login"
                className="text-[#1F2977] font-medium hover:underline"
              >
                Log in
              </Link>
            </p>
          </form>
        </div>
      </GoogleOAuthProvider>
    </section>
  );
};

export default Signup;
