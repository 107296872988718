import { toast } from "react-toastify";

const useToastSpinner = () => {
  const toastSpinner = async (
    promise,
    loadingMessage = null,
    successMessage = null,
    errorMessage = null
  ) => {
    const id = toast.loading(loadingMessage || "Loading...");
    try {
      const response = await promise;
      if (response?.status === "rejected" || response?.error) {
        throw response.error;
      }
      toast.update(id, {
        render:
          successMessage ||
          response?.data?.message ||
          "Operation completed successfully.",
        type: "success",
        autoClose: true,
        closeButton: true,
        isLoading: false,
      });
      return response;
    } catch (error) {
      toast.update(id, {
        render: errorMessage || error?.data?.message || "An error occurred.",
        type: "error",
        autoClose: true,
        closeButton: true,
        isLoading: false,
      });
      // throw error;
    }
  };

  return { toastSpinner };
};

export default useToastSpinner;
