import React, { useCallback, useMemo, useState } from "react";
import Loader from "../components/shared/Loader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {} from "../store";
import { debounce } from "@mui/material";

const SendRequest = () => {
  const [subject, setSubject] = useState("");
  const [note, setNote] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const { toastSpinner } = useToastSpinner();

  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);

  const payload = useMemo(
    () => ({ clientID: client?.ID, token }),
    [client, token]
  );

  // const handleIdBlur = useCallback(
  //   debounce((id) => {
  //     setToID(id);
  //   }, 300),
  //   []
  // );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!subject) {
      toast.error("Subject is required");
      return;
    }

    setIsSubmitting(true);

    // const res = await internalTransfer({ toID, amount, note, ...payload });
    const res = "";

    setIsSubmitting(false);

    if (res?.data) {
      toast.success(res.data.message);
      setSubject("");
      setNote("");
    } else {
      toast.error(res.error.data.message);
    }
  };

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <section>
      <h2 className="text-5xl font-bold text-white my-10">
        Send a Message to ACT office
      </h2>
      <form
        className="bg-white py-7 px-5 text-[#252525] mt-6"
        onSubmit={handleSubmit}
      >
        <div className="w-8/12">
          {/* Pay out method */}
          <div>
            <label htmlFor="payOutMethod" className="block font-medium mb-3">
              Subject
            </label>
            <input
              className="w-full rounded border border-borderColor3 p-3 focus:ring-blue-500 focus:border-blue-500"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Enter the subject of your message"
            />
          </div>

          {/* Notes */}
          <div className="mb-6">
            <label htmlFor="notes" className="block font-medium mb-3">
              Message
            </label>
            <textarea
              id="note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              rows="4"
              className="w-full border border-borderColor3 rounded p-3 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your message here..."
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-[200px] h-[50px] text-xl bg-primary text-white font-bold py-3 rounded hover:bg-blue-800 transition"
          >
            Submit
          </button>
        </div>
      </form>
    </section>
  );
};

export default SendRequest;
