import { useGoogleLogin } from "@react-oauth/google";
import React from "react";
import { successLogin, useLoginByGoogleMutation } from "../../store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../shared/Loader";
import useToastSpinner from "../../hooks/useToastSpinner";

const GoogleLoginBtn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toastSpinner } = useToastSpinner();
  const [LoginByGoogle, LoginByGoogleResult] = useLoginByGoogleMutation();

  const handleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res = await toastSpinner(
          LoginByGoogle(response?.access_token),
          "Logging in with Google...",
          "You have logged in successfully",
          "Failed to login with Google."
        );

        if (res?.data) {
          const payload = {
            token: res.data?.data?.token?.token,
            expiresIn: res.data?.data?.token?.expiresIn,
            user: res.data?.data?.user,
          };
          dispatch(successLogin(payload));
          navigate("/account");
        } else {
          // Additional error handling
          toast.error(res.error?.data?.message || "Login failed.");
        }
      } catch (error) {
        console.error("Error during Google login:", error);
        toast.error("An unexpected error occurred. Please try again.");
      }
    },
    onError: () => {
      toast.error("Login Failed");
    },
  });

  if (LoginByGoogleResult.isLoading) return <Loader />;
  return (
    <button
      onClick={handleLogin}
      className="w-full bg-[#F1F1F1] rounded-[14px] flex items-center justify-center py-3 border border-[#B1B1B1] text-[#1B1B1B] hover:bg-gray-100"
    >
      <img src="/icons/Google.png" alt="google" className="mr-2 text-xl" />
      Continue with Google
    </button>
  );
};

export default GoogleLoginBtn;
