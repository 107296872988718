import React from "react";

const BigHamburger = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="64px"
      height="64px"
    >
      <path d="M10 52H54V56H10zM10 12H54V16H10zM10 32H54V36H10z" />
    </svg>
  );
};

export default BigHamburger;
