import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const authApi = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1/auth",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      signupByPassword: builder.mutation({
        query: (credentials) => ({
          url: "/signup",
          method: "POST",
          body: credentials,
        }),
      }),
      validateEmail: builder.query({
        query: (token) => ({
          url: `/verify-email/${token}`,
          method: "GET",
        }),
      }),
      loginByPassword: builder.mutation({
        query: (credentials) => ({
          url: "/login",
          method: "POST",
          body: credentials,
        }),
      }),
      loginByPasswordWithOtp: builder.mutation({
        query: (credentials) => ({
          url: "/login/otp",
          method: "POST",
          body: credentials,
        }),
      }),
      verifyOtp: builder.mutation({
        query: (credentials) => ({
          url: "/verify-otp",
          method: "POST",
          body: credentials,
        }),
      }),
      loginByGoogle: builder.mutation({
        query: (credentials) => ({
          url: "/googleLogin",
          method: "POST",
          body: { token: credentials, provider: "google.com" },
        }),
      }),
      logout: builder.mutation({
        query: () => ({
          url: "/logout",
          method: "POST",
        }),
      }),
      resetPassword: builder.mutation({
        query: ({ token, newPassword }) => ({
          url: `/reset-password/${token}`,
          method: "POST",
          body: { newPassword },
        }),
      }),
      forgotPassword: builder.mutation({
        query: (email) => ({
          url: "/forgotPassword",
          method: "POST",
          body: { email },
        }),
      }),
      refreshToken: builder.mutation({
        query: (token) => ({
          url: "/refresh-token",
          method: "POST",
          body: { token },
        }),
      }),
    };
  },
});

export const {
  useSignupByPasswordMutation,
  useValidateEmailQuery,
  useLoginByPasswordMutation,
  useLoginByGoogleMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useRefreshTokenMutation,
  useVerifyOtpMutation,
  useLoginByPasswordWithOtpMutation,
} = authApi;
export { authApi };
