import * as yup from "yup";
const passwordRule = /^(?=.*[\W_])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const loginSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
  password: yup
    .string()
    .min(6)
    .matches(passwordRule, {
      message:
        "Password must be at least 8 characters, 1 uppercase, 1 lowercase, and 1 special character.",
    })
    .required("Required"),
});
export const otpEmailValidationSchema = yup.object({
  verificationEmail: yup
    .string()
    .email("Invalid email address.")
    .required("Email is required."),
});
