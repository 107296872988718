import React from "react";
import RequestForm from "../components/features/RequestForAccount/RequestForm";

const RequestForAccount = () => {
  return (
    <section className="max-lg:mt-32">
      <RequestForm />
    </section>
  );
};

export default RequestForAccount;
