import React, { useEffect, useState } from "react";
import calculateTransactionType from "../../../utils/calculateTransactionType";

const FilterOptions = ({ setShowFilterPopover, columns, clearFilters, data, setFilteredData }) => {
  const [filters, setFilters] = useState(
    columns.reduce((acc, col) => {
      acc[col.key] = { operator: "equals", value: "" };
      return acc;
    }, {})
  );

  // State to track filter usage
  const [filterUsage, setFilterUsage] = useState({});

  const trackFilterUsage = (key, field) => {
    setFilterUsage((prev) => ({
      ...prev,
      [key]: { ...prev[key], [field]: true },
    }));
  };

  const applyFilters = () => {
    const filtered = data.filter((item) => {
      return Object.keys(filters).every((key) => {
        const filter = filters[key];
        const itemValue = item[key];
        if (!filter.value) return true; // Skip if no filter value

        if (!filter.value) return true; // Skip if no filter value
        if (filterUsage?.Date?.value) {
          if (filter.operator === "range") {
            if (!filter.value.startDate || !filter.value.endDate) return true;
            const itemDate = new Date(item?.DDate);
            const startDate = new Date(filter.value.startDate);
            const endDate = new Date(filter.value.endDate);

            return itemDate >= startDate && itemDate <= endDate;
          } else {
            const filter = filters[key];
            const itemValue = item?.DDate;
            if (!itemValue || filter?.value) return true;
            const itemDate = new Date(itemValue).toISOString().split("T")[0];
            const filterDate = new Date(filter?.value).toISOString().split("T")[0];
            return itemDate === filterDate;
          }
        } else if (key === "TransactionType") {
          const transactionType = calculateTransactionType(item.PaidIn_PaidOut, item.OnHold);
          if (filter.operator === "equals") {
            return transactionType.toLowerCase() === filter.value.toLowerCase();
          }
          return true;
        } else if (key === "Note" || key === "MoneyType") {
          if (filter.operator === "equals") {
            return itemValue?.toString().toLowerCase().includes(filter.value.toLowerCase());
          }
          return true;
        } else if (key === "TransactionType") {
          // Calculate transaction type dynamically
          const transactionType = calculateTransactionType(item.PaidIn_PaidOut, item.OnHold);

          if (filter.operator === "equals") {
            return transactionType === filter.value;
          }
          return true;
        } else if (filter.operator === "equals") {
          return itemValue == filter.value;
        } else if (filter.operator === "not-equals") {
          return itemValue != filter.value;
        } else if (filter.operator === "greater-than") {
          return parseFloat(itemValue) > parseFloat(filter.value);
        } else if (filter.operator === "less-than") {
          return parseFloat(itemValue) < parseFloat(filter.value);
        }
      });
    });

    setFilteredData(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  const handleFilterChange = (key, field, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: { ...prev[key], [field]: value },
    }));
    if (key === "Date") {
      trackFilterUsage(key, field);
    }
  };

  return (
    <div className="w-full right-10 mt-10 bg-white shadow-lg rounded-lg p-3 border border-gray-200">
      <h3 className="text-gray-700 font-bold mb-2">Filter Options</h3>
      <div className="grid grid-cols-2 gap-4">
        {Object.keys(filters).map((key) => {
          const column = columns.find((col) => col.key === key);
          //skeep fee column
          if (key === "Fee") {
            return null;
          }
          return (
            <div key={key} className="px-4 flex items-end space-y-2">
              <div className="flex-1">
                <label className="block text-gray-500 text-sm">{column?.label}</label>
                {key === "Date" ? (
                  <div className={`flex ${filters[key].operator === "range" ? "flex-col" : "flex-row"}`}>
                    <select
                      className="border w-full flex-1 rounded-md px-3 py-2 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
                      value={filters[key].operator}
                      onChange={(e) => handleFilterChange(key, "operator", e.target.value)}
                    >
                      <option value="equals">Equals</option>
                      <option value="range">Date Range</option>
                    </select>
                    {filters[key].operator === "range" ? (
                      <div className="flex justify-between mt-2">
                        <input
                          className="border flex-1 h-10 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
                          type="date"
                          value={filters[key]?.value?.startDate || ""}
                          onChange={(e) =>
                            handleFilterChange(key, "value", {
                              ...filters[key]?.value,
                              startDate: e.target.value,
                            })
                          }
                        />
                        <span className="mx-2">to</span>
                        <input
                          className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
                          type="date"
                          value={filters[key]?.value?.endDate || ""}
                          onChange={(e) =>
                            handleFilterChange(key, "value", {
                              ...filters[key]?.value,
                              endDate: e.target.value,
                            })
                          }
                        />
                      </div>
                    ) : (
                      <input
                        className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
                        type="date"
                        value={filters[key].value}
                        onChange={(e) => handleFilterChange(key, "value", e.target.value)}
                      />
                    )}
                  </div>
                ) : (
                  <div className="flex">
                    {key === "TransactionType" || key === "MoneyType" || key === "Note" ? (
                      <select
                        className="border w-full flex-1 rounded-md px-3 py-2 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
                        value={filters[key].operator}
                        onChange={(e) => handleFilterChange(key, "operator", e.target.value)}
                      >
                        <option value="equals">Equals</option>
                      </select>
                    ) : (
                      <select
                        className="border w-full flex-1 rounded-md px-3 py-2 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
                        value={filters[key].operator}
                        onChange={(e) => handleFilterChange(key, "operator", e.target.value)}
                      >
                        <option value="equals">Equals</option>
                        <option value="not-equals">Not Equals</option>
                        <option value="greater-than">Greater Than</option>
                        <option value="less-than">Less Than</option>
                      </select>
                    )}

                    <input
                      className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
                      type="text"
                      value={filters[key].value}
                      onChange={(e) => handleFilterChange(key, "value", e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-4 gap-3 flex justify-end">
        <button
          className="bg-[#FB2727] text-white px-4 py-2 rounded-md hover:bg-[#f06969]"
          onClick={() => {
            setFilters(
              columns.reduce((acc, col) => {
                acc[col.key] = { operator: "equals", value: "" };
                return acc;
              }, {})
            );
            clearFilters();
          }}
        >
          Clear Filters
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          onClick={() => {
            setShowFilterPopover(false);
            applyFilters();
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default FilterOptions;

// import React, { useEffect, useState } from "react";

// const FilterOptions = ({
//   setShowFilterPopover,
//   // filters,
//   columns,
//   // setFilters,
//   setShowAdvancedFilters,
//   clearFilters,
//   data,
//   setFilteredData,
// }) => {
//   const [filters, setFilters] = useState(
//     columns.reduce((acc, col) => {
//       acc[col.key] = { operator: "equals", value: "" };
//       return acc;
//     }, {})
//   );

//   const applyFilters = () => {
//     const filtered = data.filter((item) => {
//       return Object.keys(filters).every((key) => {
//         const filter = filters[key];
//         const itemValue = item[key];
//         if (!filter.value) return true; // Skip if no filter value
//         switch (filter.operator) {
//           case "equals":
//             return itemValue == filter.value;
//           case "not-equals":
//             return itemValue != filter.value;
//           case "greater-than":
//             return parseFloat(itemValue) > parseFloat(filter.value);
//           case "less-than":
//             return parseFloat(itemValue) < parseFloat(filter.value);
//           case "range":
//             if (!filter.value.startDate || !filter.value.endDate) return true; // Skip if no range
//             const itemDate = new Date(itemValue);
//             const startDate = new Date(filter.value.startDate);
//             const endDate = new Date(filter.value.endDate);
//             return itemDate >= startDate && itemDate <= endDate;
//           default:
//             return true; // Default: Include item if no operator matches
//         }
//       });
//     });

//     setFilteredData(filtered);
//   };

//   // Update filteredData when filters change
//   useEffect(() => {
//     applyFilters();
//   }, [filters]);

//   const handleFilterChange = (key, field, value) => {
//     setFilters((prev) => ({
//       ...prev,
//       [key]: { ...prev[key], [field]: value },
//     }));
//   };
//   console.log(filters);
//   return (
//     <div className="w-full right-10 mt-10 bg-white shadow-lg rounded-lg p-3 border border-gray-200">
//       <h3 className="text-gray-700 font-bold mb-2">Filter Options</h3>
//       <div className="grid grid-cols-2 gap-4">
//         {Object.keys(filters).map((key) => {
//           const column = columns.find((col) => col.key === key);

//           return (
//             <div key={key} className="px-4 flex items-end space-y-2">
//               <div className="flex-1">
//                 <label className="block text-gray-500 text-sm">
//                   {column?.label}
//                 </label>
//                 {key === "Date" ? (
//                   <div
//                     className={`flex ${
//                       filters[key].operator === "range"
//                         ? "flex-col"
//                         : "flex-row"
//                     }`}
//                   >
//                     <select
//                       className="border w-full flex-1 rounded-md px-3 py-2 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
//                       value={filters[key].operator}
//                       onChange={(e) =>
//                         handleFilterChange(key, "operator", e.target.value)
//                       }
//                     >
//                       <option value="equals">Equals</option>
//                       <option value="range">Date Range</option>
//                     </select>
//                     {filters[key].operator === "range" ? (
//                       <div className="flex justify-between mt-2">
//                         <input
//                           className="border flex-1 h-10 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                           type="date"
//                           value={filters[key]?.value?.startDate || ""} // Fallback to an empty string
//                           onChange={(e) =>
//                             handleFilterChange(key, "value", {
//                               ...filters[key]?.value,
//                               startDate: e.target.value,
//                             })
//                           }
//                         />
//                         <span className="mx-2">to</span>
//                         <input
//                           className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                           type="date"
//                           value={filters[key]?.value?.endDate || ""} // Fallback to an empty string
//                           onChange={(e) =>
//                             handleFilterChange(key, "value", {
//                               ...filters[key]?.value,
//                               endDate: e.target.value,
//                             })
//                           }
//                         />
//                       </div>
//                     ) : (
//                       <input
//                         className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                         type="date"
//                         value={filters[key].value}
//                         onChange={(e) =>
//                           handleFilterChange(key, "value", e.target.value)
//                         }
//                       />
//                     )}
//                   </div>
//                 ) : (
//                   <div className="flex">
//                     <select
//                       className="border w-full flex-1 rounded-md px-3 py-2 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
//                       value={filters[key].operator}
//                       onChange={(e) =>
//                         handleFilterChange(key, "operator", e.target.value)
//                       }
//                     >
//                       <option value="equals">Equals</option>
//                       <option value="not-equals">Not Equals</option>
//                       <option value="greater-than">Greater Than</option>
//                       <option value="less-than">Less Than</option>
//                     </select>
//                     <input
//                       className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                       type="text"
//                       value={filters[key].value}
//                       onChange={(e) =>
//                         handleFilterChange(key, "value", e.target.value)
//                       }
//                     />
//                   </div>
//                 )}
//               </div>
//             </div>
//           );
//         })}
//       </div>
//       <div className="mt-4 gap-3 flex justify-end">
//         <button
//           className="bg-[#FB2727] text-white px-4 py-2 rounded-md hover:bg-[#f06969]"
//           onClick={() => {
//             setFilters(
//               columns.reduce((acc, col) => {
//                 acc[col.key] = { operator: "equals", value: "" };
//                 return acc;
//               }, {})
//             );
//             clearFilters();
//           }}
//         >
//           Clear Filters
//         </button>
//         <button
//           className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
//           onClick={() => {
//             setShowFilterPopover(false);
//             setShowAdvancedFilters(false);
//             applyFilters();
//           }}
//         >
//           Apply
//         </button>
//       </div>
//     </div>
//   );
// };

// export default FilterOptions;
// import React from "react";

// const FilterOptions = ({
//   setShowFilterPopover,
//   filters,
//   columns,
//   setFilters,
//   setShowAdvancedFilters,
//   clearFilters,
// }) => {
//   const handleFilterChange = (key, field, value) => {
//     setFilters((prev) => ({
//       ...prev,
//       [key]: { ...prev[key], [field]: value },
//     }));
//   };

//   return (
//     <div className="w-full right-10 mt-10 bg-white shadow-lg rounded-lg p-3 border border-gray-200">
//       <h3 className="text-gray-700 font-bold mb-2">Filter Options</h3>
//       <div className="grid grid-cols-2 gap-4">
//         {Object.keys(filters).map((key) => {
//           const column = columns.find((col) => col.key === key);

//           return (
//             <div key={key} className="px-4 flex items-end space-y-2">
//               <div className="flex-1">
//                 <label className="block text-gray-500 text-sm">
//                   {column?.label}
//                 </label>

//                 {/* For Date field */}
//                 {key === "Date" ? (
//                   <div
//                     className={`flex ${
//                       filters[key].operator === "range"
//                         ? "flex-col"
//                         : "flex-row"
//                     }`}
//                   >
//                     <select
//                       className="border w-full flex-1 rounded-md px-3 py-2 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
//                       value={filters[key].operator}
//                       onChange={(e) =>
//                         handleFilterChange(key, "operator", e.target.value)
//                       }
//                     >
//                       <option value="equals">Equals</option>
//                       <option value="range">Date Range</option>
//                     </select>

//                     {filters[key].operator === "range" ? (
//                       <div className="flex justify-between mt-2">
//                         <input
//                           className="border flex-1 h-10 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                           type="date"
//                           value={filters[key].value.startDate || ""}
//                           onChange={(e) =>
//                             handleFilterChange(key, "value", {
//                               ...filters[key].value,
//                               startDate: e.target.value,
//                             })
//                           }
//                         />
//                         <span className="mx-2">to</span>
//                         <input
//                           className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                           type="date"
//                           value={filters[key].value.endDate || ""}
//                           onChange={(e) =>
//                             handleFilterChange(key, "value", {
//                               ...filters[key].value,
//                               endDate: e.target.value,
//                             })
//                           }
//                         />
//                       </div>
//                     ) : (
//                       <input
//                         className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                         type="date"
//                         value={filters[key].value}
//                         onChange={(e) =>
//                           handleFilterChange(key, "value", e.target.value)
//                         }
//                       />
//                     )}
//                   </div>
//                 ) : key === "TransactionType" ||
//                   key === "MoneyType" ||
//                   key === "Note" ? (
//                   // For TransactionType, MoneyType, Note fields
//                   <div className="flex">
//                     <select
//                       className="border w-full flex-1 rounded-md px-3 py-2 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
//                       value={filters[key].operator}
//                       onChange={(e) =>
//                         handleFilterChange(key, "operator", e.target.value)
//                       }
//                     >
//                       <option value="equals">Equals</option>
//                       <option value="not-equals">Not Equals</option>
//                     </select>
//                     <input
//                       className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                       type="text"
//                       value={filters[key].value}
//                       onChange={(e) =>
//                         handleFilterChange(key, "value", e.target.value)
//                       }
//                     />
//                   </div>
//                 ) : (
//                   // For other fields (Amount, Total, etc.)
//                   <div className="flex">
//                     <select
//                       className="border flex-1 w-full rounded-md px-3 py-2 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
//                       value={filters[key].operator}
//                       onChange={(e) =>
//                         handleFilterChange(key, "operator", e.target.value)
//                       }
//                     >
//                       <option value="equals">Equals</option>
//                       <option value="not-equals">Not Equals</option>
//                       <option value="greater-than">Greater Than</option>
//                       <option value="less-than">Less Than</option>
//                     </select>
//                     <input
//                       className="border flex-1 h-10 rounded-md ml-2 px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
//                       type="text"
//                       value={filters[key].value}
//                       onChange={(e) =>
//                         handleFilterChange(key, "value", e.target.value)
//                       }
//                     />
//                   </div>
//                 )}
//               </div>
//             </div>
//           );
//         })}
//       </div>
//       <div className="mt-4 gap-3 flex justify-end">
//         <button
//           className="bg-[#FB2727] text-white px-4 py-2 rounded-md hover:bg-[#f06969]"
//           onClick={() => {
//             setShowFilterPopover(false);
//             setShowAdvancedFilters(false);
//             clearFilters();
//           }}
//         >
//           Clear Filters
//         </button>
//         <button
//           className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
//           onClick={() => {
//             setShowFilterPopover(false);
//             setShowAdvancedFilters(false);
//           }}
//         >
//           Apply
//         </button>
//       </div>
//     </div>
//   );
// };

// export default FilterOptions;
