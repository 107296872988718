import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import formatDate from "../../../utils/formateDate";
import { useSendInvoiceMailMutation } from "../../../store";
import { toast } from "react-toastify";
import calculateTransactionType from "../../../utils/calculateTransactionType";
import pdfBanner from "../../../assets/pdfBanner.png";
import { download, generateCsv, mkConfig } from "export-to-csv";
import calculateTransactionTypeWithIcon from "../../../utils/calculateTransactionTypeWithIcon";
import formatAmount from "../../../utils/formateAmount";
import useToastSpinner from "../../../hooks/useToastSpinner";
import BigHamburger from "../../icons/BigHamburger";
import FilterOptions from "./FilterOptions";
import { Tooltip } from "@mui/material";
import { SyncAlt } from "@mui/icons-material";
import getTodayDate from "../../../utils/getTodayDate";
import TableHeader from "./TableHeader";
import letterhead from "../../../assets/Letterhead.jpg";
import receiptImage from "../../../assets/receiptBg.jpg";

const CustomTable = ({ columns, data, actions }) => {
  const [filters, setFilters] = useState(
    columns.reduce((acc, col) => {
      acc[col.key] = { operator: "equals", value: "" };
      return acc;
    }, {})
  );
  const [tColumnInfo, setTColumnInfo] = useState("");
  const [rowInfo, setRowInfo] = useState("");
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [filteredData, setFilteredData] = useState(data);
  const [density, setDensity] = useState("comfortable");
  const [sortDirection, setSortDirection] = useState({});

  const [pdfPreview, setPdfPreview] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = (pdfBlob) => {
    const blobUrl = URL.createObjectURL(pdfBlob);
    setPdfPreview(blobUrl);
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setPdfPreview(null);
    setIsPreviewOpen(false);
  };

  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);

  const { toastSpinner } = useToastSpinner();
  useEffect(() => {
    setFilteredData(data);
  }, [data]);
  const handleFilterChange = (e) => {
    const filter = e.target.value.toLowerCase();
    setFilterText(filter);

    const filtered = data.filter((row) => {
      return Object.keys(row).some((key) => {
        const value = row[key];

        // Handle raw date comparison
        if (key === "DDate") {
          const rawDate = new Date(value).toISOString().split("T")[0]; // YYYY-MM-DD
          return rawDate.includes(filter);
        }

        // Handle transactionType dynamically
        if (key === "PaidIn_PaidOut") {
          const { transactionType } = calculateTransactionTypeWithIcon(
            row.PaidIn_PaidOut,
            row.OnHold
          );
          return transactionType.toLowerCase().includes(filter);
        }

        // Handle strings and numbers
        if (typeof value === "string") {
          return value.toLowerCase().includes(filter);
        }

        if (typeof value === "number") {
          return value.toString().includes(filter);
        }

        return false;
      });
    });

    setFilteredData(filtered);
  };

  const [sendInvoiceMail] = useSendInvoiceMailMutation();
  const csvConfig = useMemo(
    () =>
      mkConfig({
        fieldSeparator: ",",
        useKeysAsHeaders: true,
        filename: `Transaction ACT account ${client?.ID}, ${getTodayDate()}`,
      }),
    [client?.ID]
  );

  const types = useMemo(
    () => ({
      1: "Paid In",
      "-1": "Paid Out",
      2: "Credit",
      "-2": "Debit",
      "-3": "Fees",
      4: "Int. Received",
      "-4": "Int. Send",
    }),
    []
  );

  // Handle sorting
  const handleSort = (key) => {
    const currentDirection = sortDirection[key] || "asc";
    const direction = currentDirection === "asc" ? "desc" : "asc";

    const sortedData = [...data].sort((a, b) => {
      let aValue, bValue;

      // Handle specific keys
      if (key === "Date") {
        aValue = new Date(a.DDate);
        bValue = new Date(b.DDate);
      } else if (key === "TransactionType") {
        aValue = calculateTransactionTypeWithIcon(
          a.PaidIn_PaidOut,
          a.OnHold
        ).transactionType;
        bValue = calculateTransactionTypeWithIcon(
          b.PaidIn_PaidOut,
          b.OnHold
        ).transactionType;
      } else {
        // Default case for numeric or string comparison
        aValue = isNaN(a[key]) ? a[key] : parseFloat(a[key]);
        bValue = isNaN(b[key]) ? b[key] : parseFloat(b[key]);
      }

      // Compare values
      if (direction === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });

    setFilteredData(sortedData);
    setSortDirection({ [key]: direction });
  };

  const handleExportRows = useCallback(
    (rows) => {
      const rowData = filteredData.map((row) => {
        const { Amount, PaidIn_PaidOut, MoneyType, Fee, Total, Note, OnHold } =
          row;
        const noneNull = JSON.parse(
          JSON.stringify({ Amount, MoneyType, Fee, Total, Note }).replace(
            /null/g,
            '""'
          )
        );
        return {
          Date: new Date(row.DDate).toLocaleDateString("en-GB"),
          "Transaction type": OnHold
            ? `Pending ${types[PaidIn_PaidOut]}`
            : types[PaidIn_PaidOut],
          ...noneNull,
        };
      });
      const csv = generateCsv(csvConfig)(rowData);
      download(csvConfig)(csv);
    },
    [csvConfig, filteredData, types]
  );

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const downloadFullTablePDF = async (row) => {
    setTimeout(async () => {
      const doc = new jsPDF("p", "pt", "a4"); // PDF in portrait mode, size A4
      const input = document.getElementById("fullInvoice");
      const canvas = await html2canvas(input, { backgroundColor: "#ffffff" });

      const imgData = canvas.toDataURL("image/png");
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const aspectRatio = canvasHeight / canvasWidth;

      const imgHeightInPDF = pdfWidth * aspectRatio;

      let yPosition = 0;

      while (yPosition < canvasHeight) {
        const remainingHeight = canvasHeight - yPosition;
        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = canvas.width;

        // If it's the last page, adjust canvas height to match remaining content
        if (remainingHeight < (canvas.width / pdfWidth) * pdfHeight) {
          pageCanvas.height = remainingHeight; // Only the remaining content
        } else {
          pageCanvas.height = (canvas.width / pdfWidth) * pdfHeight; // Full page
        }

        const ctx = pageCanvas.getContext("2d");
        ctx.drawImage(
          canvas,
          0,
          yPosition,
          canvas.width,
          pageCanvas.height,
          0,
          0,
          pageCanvas.width,
          pageCanvas.height
        );

        const pageData = pageCanvas.toDataURL("image/png");

        // Add page and center last page content
        if (yPosition > 0) doc.addPage();
        if (remainingHeight < pdfHeight) {
          const verticalOffset = (pdfHeight - remainingHeight) / 2; // Center vertically
          doc.addImage(
            pageData,
            "PNG",
            0,
            verticalOffset,
            pdfWidth,
            remainingHeight * (pdfWidth / canvasWidth)
          );
        } else {
          doc.addImage(pageData, "PNG", 0, 0, pdfWidth, pdfHeight);
        }

        yPosition += pageCanvas.height;
      }

      doc.save(`Transaction ACT account ${client?.ID}, ${getTodayDate()}.pdf`);
    }, 100);
  };

  const downloadPDF = async (row) => {
    const invoiceType = "Receipt";
    setTimeout(async () => {
      const doc = new jsPDF("p", "pt", "a4");
      const input = document.getElementById("invoice");
      await html2canvas(input, { backgroundColor: "#ffffff" }).then(
        (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          doc.setFillColor(255, 255, 255);
          doc.rect(0, 0, pdfWidth, pdfHeight, "F");

          doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          doc.save(
            `${invoiceType} ${formatDate(row.DDate)}, ACT#${client?.ID}.pdf`
          );
        }
      );
    }, 100);
  };

  const generateAndSendPDF = (tableData) => {
    // Invoice type
    const invoiceType =
      tableData?.PaidIn_PaidOut < 0 ? "Remittance" : "Receipt";
    setTimeout(() => {
      const doc = new jsPDF("p", "pt", "a4");
      const input = document.getElementById("invoice");

      html2canvas(input, { backgroundColor: "#ffffff" }).then(
        async (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          doc.setFillColor(255, 255, 255);
          doc.rect(0, 0, pdfWidth, pdfHeight, "F");

          doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

          // Convert PDF to blob
          const pdfBlob = doc.output("blob");

          // Create FormData object and append the blob
          const formData = new FormData();
          // Receipt Jan 03 2025, ACT#5543
          formData.append(
            "pdf",
            pdfBlob,
            `${invoiceType} ${formatDate(tableData.DDate)}, ACT#${
              client?.ID
            }.pdf`
          );
          if (!tableData) {
            toast.error(
              "Invalid parameters. Ensure all required data is provided."
            );
            return;
          }
          try {
            await toastSpinner(
              sendInvoiceMail({
                token,
                clientID: client?.ID,
                IDTransactions: tableData.IDTransactions,
                formData,
                invoiceType,
              }),
              "Sending invoice...",
              "Email has been sent successfully.",
              "There was a server-side error while sending the invoice!"
            );

            // toast.success("Email has been sent successfully.");
          } catch (error) {
            toast.error("There was an server side error!");
          }
        }
      );
    }, 100);
  };

  const createPDFBlob = async (row) => {
    if (row.PaidIn_PaidOut < 0) {
      toast.error("This transaction is a debit.");
      return null;
    }

    const doc = new jsPDF("p", "pt", "a4");
    const input = document.getElementById("invoice");

    const canvas = await html2canvas(input, { backgroundColor: "#ffffff" });
    const imgData = canvas.toDataURL("image/png");
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    doc.setFillColor(255, 255, 255);
    doc.rect(0, 0, pdfWidth, pdfHeight, "F");
    doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

    return doc.output("blob");
  };

  const downloadPDFVIEW = async (row) => {
    const pdfBlob = await createPDFBlob(row);
    if (pdfBlob) {
      openPreview(pdfBlob);
    }
  };

  const generateAndSendPDFView = async (row) => {
    const pdfBlob = await createPDFBlob(row);
    if (pdfBlob) {
      openPreview(pdfBlob);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const toggleMenu = (index, row) => {
    setTColumnInfo(row);
    setRowInfo(row);
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  const closeMenu = () => {
    setOpenMenuIndex(null);
  };

  const toggleFilterPopover = () => {
    setShowFilterPopover((prev) => !prev);
    setShowAdvancedFilters(false); // Reset advanced filter modal visibility
  };

  // Remove all filters
  const clearFilters = () => {
    setFilteredData(data);
  };

  const highlightText = (text, filter) => {
    if (!filter) return text;

    const regex = new RegExp(`(${filter})`, "gi");
    const parts = text?.split(regex);
    return parts?.map((part, index) =>
      part?.toLowerCase() === filter?.toLowerCase() ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const {
    Amount,
    DDate,
    Note,
    PaidIn_PaidOut,
    Total,
    OnHold,
    SentToA_FullName,
    IDTransactions,
  } = tColumnInfo || {};

  const formattedTransactionDate = formatDate(DDate);
  const transactionType = calculateTransactionType(PaidIn_PaidOut, OnHold);
  const invoiceType = PaidIn_PaidOut < 0 ? "Remittance" : "Receipt";

  const toggleDensity = () => {
    const densityOptions = ["compact", "comfortable", "standard"];
    const currentIndex = densityOptions.indexOf(density);
    const nextDensity =
      densityOptions[(currentIndex + 1) % densityOptions.length];
    setDensity(nextDensity);
  };

  return (
    <>
      <TableHeader
        density={density}
        downloadFullTablePDF={downloadFullTablePDF}
        filterText={filterText}
        handleExportRows={handleExportRows}
        handleFilterChange={handleFilterChange}
        toggleDensity={toggleDensity}
        toggleFilterPopover={toggleFilterPopover}
      />
      <div
        className={`transition-all duration-500 ease-in-out overflow-hidden transform ${
          showFilterPopover
            ? "h-auto opacity-100 scale-100"
            : "h-0 opacity-0 scale-95"
        }`}
      >
        <div className="p-4 bg-gray-100 rounded shadow-md">
          <FilterOptions
            data={data}
            clearFilters={clearFilters}
            columns={columns}
            filters={filters}
            setFilters={setFilters}
            setShowAdvancedFilters={setShowAdvancedFilters}
            setShowFilterPopover={setShowFilterPopover}
            showFilterPopover={showFilterPopover}
            setFilteredData={setFilteredData}
          />
        </div>
      </div>

      <div
        className={`table-wrapper overflow-hidden mt-${
          showFilterPopover ? "8" : "4"
        } transition-all duration-500 ease-in-out`}
      >
        <table
          className={`custom-table ${
            density === "compact"
              ? "borderSpace3" // Compact height
              : density === "comfortable"
              ? "borderSpace2" // Comfortable height
              : "borderSpace1" // Standard height
          }`}
        >
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key}>
                  <div className="custom-table-column">
                    {column.label}{" "}
                    <div>
                      <Tooltip title="Sort">
                        <SyncAlt
                          fontSize="small"
                          onClick={() => handleSort(column.key)}
                          className="rotate cursor-pointer"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </th>
              ))}
              {actions && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((row, index) => (
              <tr
                key={row.ID}
                className={`${
                  density === "compact"
                    ? "h-8" // Compact height
                    : density === "comfortable"
                    ? "h-12" // Comfortable height
                    : "h-16" // Standard height
                }`}
              >
                {columns.map((column) => (
                  <td
                    key={column.key}
                    className={`${
                      row.OnHold ? "text-[#888888]" : "text-[#888]"
                    }`}
                  >
                    {column.key === "Date" ? (
                      highlightText(formatDate(row.DDate), filterText)
                    ) : // <p>{formatDate(row.DDate)}</p>
                    column.key === "TransactionType" ? (
                      <div className="flex justify-between items-center">
                        {/* Destructure transaction type and icon */}
                        {(() => {
                          const { transactionType, transactionIcon } =
                            calculateTransactionTypeWithIcon(
                              row?.PaidIn_PaidOut,
                              row?.OnHold
                            );
                          return (
                            <div className="w-full max-w-40 flex items-center justify-between">
                              <p>
                                {highlightText(transactionType, filterText)}
                              </p>
                              {transactionType.includes("Pending") ? (
                                transactionIcon
                              ) : (
                                <img
                                  src={transactionIcon}
                                  alt={transactionType}
                                  className="w-4 h-4 cursor-pointer"
                                />
                              )}
                            </div>
                          );
                        })()}
                      </div>
                    ) : column.key === "Amount" ? (
                      <div className="flex items-center w-4 h-4">
                        <img
                          className="w-[13px] h-[13px]"
                          src="/icons/sidebar/Pound.png"
                          alt=""
                        />
                        {/* <p>{row?.Amount.toFixed(2)}</p> */}
                        {highlightText(formatAmount(row?.Amount), filterText)}
                      </div>
                    ) : column.key === "Fee" ? (
                      row?.Fee == 0 ? (
                        ""
                      ) : (
                        <div className="flex items-center w-4 h-4">
                          <img
                            className="w-[13px] h-[13px]"
                            src="/icons/sidebar/Pound.png"
                            alt=""
                          />
                          {/* <p>{row?.Fee.toFixed(2)}</p> */}
                          {highlightText(row?.Fee.toFixed(2), filterText)}
                        </div>
                      )
                    ) : column.key === "Total" ? (
                      <div className="flex items-center w-4 h-4">
                        <img
                          className="w-[13px] h-[13px]"
                          src="/icons/sidebar/Pound.png"
                          alt=""
                        />
                        {highlightText(formatAmount(row?.Total), filterText)}
                      </div>
                    ) : column.key === "Note" ? (
                      <div className="min-w-52">
                        <p className="min-w-52">
                          {highlightText(row?.Note, filterText)}
                        </p>
                      </div>
                    ) : (
                      highlightText(row[column?.key]?.toString(), filterText)
                    )}
                  </td>
                ))}
                {actions && (
                  <td className="">
                    <button onClick={() => toggleMenu(index, row)}>
                      <img src="/icons/More.png" alt="" />
                    </button>
                    {/* Popover/Modal */}
                    {openMenuIndex === index && (
                      <div className="absolute right-6 mt-6 w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-10">
                        <div className="py-1">
                          <button
                            onClick={() => {
                              downloadPDFVIEW(row);
                              closeMenu();
                            }}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                          >
                            Download Receipt
                          </button>
                          <button
                            onClick={() => {
                              generateAndSendPDFView(row);
                              closeMenu();
                            }}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                          >
                            Receipt Email
                          </button>
                        </div>
                      </div>
                    )}
                  </td>
                )}
                {isPreviewOpen && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div className="bg-white rounded-lg overflow-hidden shadow-lg w-full h-full max-w-none">
                      <div className="flex justify-between items-center px-4 py-2 border-b">
                        <h2 className="text-lg font-semibold">PDF Preview</h2>
                        <button
                          onClick={closePreview}
                          className="text-gray-600 hover:text-gray-800 text-2xl font-bold"
                        >
                          &times;
                        </button>
                      </div>
                      <div className="p-4 h-[90vh]">
                        <iframe
                          src={pdfPreview}
                          className="w-full h-full border-0"
                        ></iframe>
                      </div>
                      <div className="flex justify-end px-4 py-2 border-t bg-gray-50">
                        <button
                          onClick={() => {
                            downloadPDF(row);
                          }}
                          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
                        >
                          Download
                        </button>
                        <button
                          onClick={() => {
                            closePreview();
                            generateAndSendPDF(row);
                          }}
                          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                        >
                          Send Email
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        {filteredData.length > rowsPerPage && (
          <div className="pagination">
            <button
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
              className="page-button"
            >
              First
            </button>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="page-button"
            >
              &laquo;
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`page-button ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="page-button"
            >
              &raquo;
            </button>
            <button
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
              className="page-button"
            >
              Last
            </button>
          </div>
        )}
        {/* for pdf */}
        <div
          id="invoice"
          style={{
            fontFamily: "Arial, sans-serif",
            margin: "0",
            padding: "0",
            boxSizing: "border-box",
            width: "100%",
            position: "absolute",
            left: "-1500px",
            top: "-1500px",
          }}
          className="flex items-center justify-center min-h-screen bg-gray-100"
        >
          <div className="h-auto w-full p-6 bg-white shadow-md rounded-lg">
            <img src={letterhead} alt="Letterhead" className="w-full mb-4" />
            <div className="relative">
              <img
                src={receiptImage}
                alt="Receipt"
                className="w-full mx-auto mb-4 rounded-lg"
              />
              <h1 className="absolute top-52 left-1/2 transform -translate-x-1/2 text-5xl font-bold text-center">
                Donation Receipt
              </h1>
              <p className="absolute top-52 left-14 text-2xl">
                <span>Date:</span> {formattedTransactionDate}
              </p>
              <p className="absolute top-52 bg-[#F6F6F6] right-14 text-2xl">
                <span>Receipt No. </span> {IDTransactions - 10000}
              </p>
              <p className="absolute top-1/2 left-1/2 text-4xl transform -translate-x-1/2">
                <span>Name:</span> {SentToA_FullName}
              </p>
              <p className="absolute bottom-56 left-14 text-2xl">
                <span>Amount:</span> £ {Total}
              </p>
              <p className="absolute bg-transparent bottom-56 right-14 text-2xl">
                <span>Signature A Adler</span>
              </p>
            </div>
            <p className="text-center text-6xl font-semibold">Thank you</p>
          </div>
        </div>
        {/* ....................................................... */}
        <div
          id="fullInvoice"
          style={{
            fontFamily: "Arial, sans-serif",
            margin: "0",
            padding: "0",
            boxSizing: "border-box",
            width: "100%",
            position: "absolute",
            left: "-15000px",
            top: "-15000px",
          }}
        >
          <div
            style={{ width: "100%", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}
            className="forceWhite"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
              className="forceWhite"
            >
              <img style={{ width: "100%" }} src={pdfBanner} alt="Logo" />
              <p
                style={{
                  paddingRight: "130px",
                  paddingBottom: "50px",
                  color: "#61605f",
                }}
                className="forceWhite"
              >
                {formatDate(new Date())}
              </p>
            </div>
            <div
              style={{
                padding: "70px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              className="forceWhite"
            >
              <div
                style={{
                  width: "90%",
                  fontSize: "16px",
                  padding: "10px",
                  height: "100px",
                  border: "1px solid #000",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "start",
                }}
                className="forceWhite"
              >
                <strong style={{ margin: "0" }}>Charity details:</strong>
                <p style={{ margin: "0" }}>Name: {client?.FullName}</p>
                <p style={{ margin: "0" }}>account number: 21778</p>
                <p style={{ margin: "0" }}>Mobile number: {client?.MobileUK}</p>
              </div>
            </div>
            <div style={{ paddingLeft: "80px", paddingRight: "80px" }}>
              <div
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  paddingBottom: "10px",
                  justifyContent: "center",
                  border: "1px solid #000",
                }}
                className="forceWhite"
              >
                <table className="custom-table">
                  <thead>
                    <tr>
                      {columns.map((column) => (
                        <th key={column.key}>
                          <div className="custom-table-column">
                            {column.label}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((row, index) => (
                      <tr key={row.ID}>
                        {columns.map((column) => (
                          <td key={column.key}>
                            {column.key === "Date" ? (
                              <p>{formatDate(row.DDate)}</p>
                            ) : column.key === "TransactionType" ? (
                              <p>
                                {calculateTransactionType(
                                  row?.PaidIn_PaidOut,
                                  row?.OnHold
                                )}
                              </p>
                            ) : column.key === "Note" ? (
                              <p>{row?.Note}</p>
                            ) : (
                              highlightText(
                                row[column?.key]?.toString(),
                                filterText
                              )
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomTable;

// CLEAR VERSION
// const CustomTable = ({ columns, data, actions }) => {
//   const [rowInfo, setRowInfo] = useState("");
//   const [openMenuIndex, setOpenMenuIndex] = useState(null);
//   const [filterText, setFilterText] = useState("");

//   const [currentPage, setCurrentPage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(15);
//   const [filteredData, setFilteredData] = useState(data);

//   //   // Pagination logic
//   const indexOfLastRow = currentPage * rowsPerPage;
//   const indexOfFirstRow = indexOfLastRow - rowsPerPage;
//   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };
//   const toggleMenu = (index, row) => {
//     setRowInfo(row);
//     setOpenMenuIndex(openMenuIndex === index ? null : index);
//   };

//   const closeMenu = () => {
//     setOpenMenuIndex(null);
//   };

//   const highlightText = (text, filter) => {
//     if (!filter) return text;

//     const regex = new RegExp(`(${filter})`, "gi");
//     const parts = text?.split(regex);
//     return parts?.map((part, index) =>
//       part?.toLowerCase() === filter?.toLowerCase() ? (
//         <span key={index} className="highlight">
//           {part}
//         </span>
//       ) : (
//         part
//       )
//     );
//   };

//   const totalPages = Math.ceil(filteredData.length / rowsPerPage);

//   return (
//     <>
//       <div className="flex justify-between">
//         <div className="min-w-[300px]">
//           <div className="relative">
//             <span className="absolute inset-y-0 left-3 flex items-center text-gray-400">
//               <svg
//                 className="w-5 h-5"
//                 fill="none"
//                 stroke="currentColor"
//                 strokeWidth="2"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   d="M21 21l-4.35-4.35M10 18a8 8 0 100-16 8 8 0 000 16z"
//                 ></path>
//               </svg>
//             </span>
//             <input
//               type="text"
//               placeholder="Search"
//               className="w-full rounded-full pl-10 pr-4 py-3 text-sm text-[#888888] placeholder-[#888888] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
//             />
//           </div>
//         </div>
//       </div>
//       <div className="table-wrapper overflow-hidden">
//         <table className="custom-table">
//           <thead>
//             <tr>
//               {columns.map((column) => (
//                 <th key={column.key}>
//                   <div className="custom-table-column">{column.label}</div>
//                 </th>
//               ))}
//               {actions && <th>Actions</th>}
//             </tr>
//           </thead>
//           <tbody>
//             {currentRows.map((row, index) => (
//               <tr key={row.ID}>
//                 {columns.map((column) => (
//                   <td
//                     key={column.key}
//                     className={`${
//                       row.OnHold ? "text-[#888888]" : "text-[#888]"
//                     }`}
//                   >
//                     {column.key === "Date" ? (
//                       <p>{formatDate(row.DDate)}</p>
//                     ) : column.key === "TransactionType" ? (
//                       <div className="flex justify-between items-center">
//                         {/* Destructure transaction type and icon */}
//                         {(() => {
//                           const { transactionType, transactionIcon } =
//                             calculateTransactionTypeWithIcon(
//                               row?.PaidIn_PaidOut,
//                               row?.OnHold
//                             );

//                           return (
//                             <div className="w-full flex items-center justify-between">
//                               <p>{transactionType}</p>
//                               {transactionType.includes("Pending") ? (
//                                 transactionIcon
//                               ) : (
//                                 <img
//                                   src={transactionIcon}
//                                   alt={transactionType}
//                                   className="w-4 h-4 cursor-pointer"
//                                 />
//                               )}
//                             </div>
//                           );
//                         })()}
//                       </div>
//                     ) : column.key === "Amount" ? (
//                       <div className="flex items-center w-4 h-4">
//                         <img src="/icons/sidebar/Pound.png" alt="" />
//                         <p>{row?.Amount.toFixed(2)}</p>
//                       </div>
//                     ) : column.key === "Fee" ? (
//                       row?.Fee == 0 ? (
//                         ""
//                       ) : (
//                         <div className="flex items-center w-4 h-4">
//                           <img src="/icons/sidebar/Pound.png" alt="" />
//                           <p>{row?.Fee.toFixed(2)}</p>
//                         </div>
//                       )
//                     ) : column.key === "Total" ? (
//                       <div className="flex items-center w-4 h-4">
//                         <img src="/icons/sidebar/Pound.png" alt="" />
//                         <p>{row?.Total.toFixed(2)}</p>
//                       </div>
//                     ) : column.key === "Note" ? (
//                       <p>{row?.Note}</p>
//                     ) : (
//                       highlightText(row[column?.key]?.toString(), filterText)
//                     )}
//                   </td>
//                 ))}
//                 {actions && (
//                   <td className="actions-column">
//                     <button onClick={() => toggleMenu(index, row)}>
//                       <img src="/icons/More.png" alt="" />
//                     </button>
//                     {/* Popover/Modal */}
//                     {openMenuIndex === index && (
//                       <div className="absolute right-6 mt-6 w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-10">
//                         <div className="py-1">
//                           <button
//                             onClick={() => {
//                               closeMenu();
//                             }}
//                             className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
//                           >
//                             Download Receipt
//                           </button>
//                           <button
//                             onClick={() => {
//                               closeMenu();
//                             }}
//                             className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
//                           >
//                             Receipt Email
//                           </button>
//                         </div>
//                       </div>
//                     )}
//                   </td>
//                 )}
//               </tr>
//             ))}
//           </tbody>
//         </table>

//         {/* Pagination */}
//         {filteredData.length > rowsPerPage && (
//           <div className="pagination">
//             <button
//               onClick={() => handlePageChange(1)}
//               disabled={currentPage === 1}
//               className="page-button"
//             >
//               First
//             </button>
//             <button
//               onClick={() => handlePageChange(currentPage - 1)}
//               disabled={currentPage === 1}
//               className="page-button"
//             >
//               &laquo;
//             </button>
//             {[...Array(totalPages)].map((_, index) => (
//               <button
//                 key={index}
//                 onClick={() => handlePageChange(index + 1)}
//                 className={`page-button ${
//                   currentPage === index + 1 ? "active" : ""
//                 }`}
//               >
//                 {index + 1}
//               </button>
//             ))}
//             <button
//               onClick={() => handlePageChange(currentPage + 1)}
//               disabled={currentPage === totalPages}
//               className="page-button"
//             >
//               &raquo;
//             </button>
//             <button
//               onClick={() => handlePageChange(totalPages)}
//               disabled={currentPage === totalPages}
//               className="page-button"
//             >
//               Last
//             </button>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default CustomTable;

// const CustomTable = ({ columns, data, actions }) => {
//   const [rowInfo, setRowInfo] = useState("");
//   const [openMenuIndex, setOpenMenuIndex] = useState(null);
//   const [filterText, setFilterText] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(15);
//   const [filteredData, setFilteredData] = useState(data);
//   const [density, setDensity] = useState("comfortable"); // Default density

//   // Pagination logic
//   const indexOfLastRow = currentPage * rowsPerPage;
//   const indexOfFirstRow = indexOfLastRow - rowsPerPage;
//   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };

//   const toggleMenu = (index, row) => {
//     setRowInfo(row);
//     setOpenMenuIndex(openMenuIndex === index ? null : index);
//   };

//   const closeMenu = () => {
//     setOpenMenuIndex(null);
//   };

//   const toggleDensity = () => {
//     const densityOptions = ["compact", "comfortable", "standard"];
//     const currentIndex = densityOptions.indexOf(density);
//     const nextDensity =
//       densityOptions[(currentIndex + 1) % densityOptions.length];
//     setDensity(nextDensity);
//   };

//   const totalPages = Math.ceil(filteredData.length / rowsPerPage);

//   return (
//     <>
//       {/* Toolbar with density toggle */}
//       <div className="flex justify-between items-center mb-4">
//         <div className="min-w-[300px]">
//           <div className="relative">
//             <span className="absolute inset-y-0 left-3 flex items-center text-gray-400">
//               <svg
//                 className="w-5 h-5"
//                 fill="none"
//                 stroke="currentColor"
//                 strokeWidth="2"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   d="M21 21l-4.35-4.35M10 18a8 8 0 100-16 8 8 0 000 16z"
//                 ></path>
//               </svg>
//             </span>
//             <input
//               type="text"
//               placeholder="Search"
//               className="w-full rounded-full pl-10 pr-4 py-3 text-sm text-[#888888] placeholder-[#888888] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
//             />
//           </div>
//         </div>
//         <button
//           className="px-4 py-2 text-sm bg-gray-200 rounded hover:bg-gray-300"
//           onClick={toggleDensity}
//         >
//           {density === "compact"
//             ? "Compact"
//             : density === "comfortable"
//             ? "Comfortable"
//             : "Standard"}
//         </button>
//       </div>

//       {/* Table */}
//       <div className="table-wrapper overflow-hidden">
//         <table
//           className={`custom-table ${
//             density === "compact"
//               ? "borderSpace3" // Compact height
//               : density === "comfortable"
//               ? "borderSpace2" // Comfortable height
//               : "borderSpace1" // Standard height
//           }`}
//         >
//           <thead>
//             <tr>
//               {columns.map((column) => (
//                 <th key={column.key}>
//                   <div className="custom-table-column">{column.label}</div>
//                 </th>
//               ))}
//               {actions && <th>Actions</th>}
//             </tr>
//           </thead>
//           <tbody>
//             {currentRows.map((row, index) => (
//               <tr
//                 key={row.ID}
//                 className={`${
//                   density === "compact"
//                     ? "h-8" // Compact height
//                     : density === "comfortable"
//                     ? "h-12" // Comfortable height
//                     : "h-16" // Standard height
//                 }`}
//               >
//                 {columns.map((column) => (
//                   <td key={column.key}>{row[column.key]}</td>
//                 ))}
//                 {actions && (
//                   <td className="actions-column">
//                     <button onClick={() => toggleMenu(index, row)}>
//                       <img src="/icons/More.png" alt="" />
//                     </button>
//                   </td>
//                 )}
//               </tr>
//             ))}
//           </tbody>
//         </table>

//         {/* Pagination */}
//         {filteredData.length > rowsPerPage && (
//           <div className="pagination">
//             <button
//               onClick={() => handlePageChange(1)}
//               disabled={currentPage === 1}
//               className="page-button"
//             >
//               First
//             </button>
//             <button
//               onClick={() => handlePageChange(currentPage - 1)}
//               disabled={currentPage === 1}
//               className="page-button"
//             >
//               &laquo;
//             </button>
//             {[...Array(totalPages)].map((_, index) => (
//               <button
//                 key={index}
//                 onClick={() => handlePageChange(index + 1)}
//                 className={`page-button ${
//                   currentPage === index + 1 ? "active" : ""
//                 }`}
//               >
//                 {index + 1}
//               </button>
//             ))}
//             <button
//               onClick={() => handlePageChange(currentPage + 1)}
//               disabled={currentPage === totalPages}
//               className="page-button"
//             >
//               &raquo;
//             </button>
//             <button
//               onClick={() => handlePageChange(totalPages)}
//               disabled={currentPage === totalPages}
//               className="page-button"
//             >
//               Last
//             </button>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default CustomTable;
