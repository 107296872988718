import React from "react";

const Grants = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-br from-gray-800 to-black text-white">
      <div className="text-center space-y-8">
        <h1 className="text-5xl font-bold">Coming Soon</h1>
        <p className="text-lg text-gray-300">
          We are working hard to bring something amazing! Stay tuned.
        </p>
        <div className="flex justify-center space-x-4">
          <input
            type="email"
            placeholder="Enter your email"
            className="px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
          />
          <button className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition">
            Notify Me
          </button>
        </div>
      </div>
    </div>
  );
};

export default Grants;
