import React from "react";

const Recurring = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-br text-white">
      <div className="text-center space-y-8">
        <h1 className="text-5xl font-bold">Coming Soon</h1>
        <p className="text-lg text-gray-300">
          We are working hard to bring something amazing! Stay tuned.
        </p>
      </div>
    </div>
  );
};

export default Recurring;
