import React from "react";
import BigHamburger from "../../icons/BigHamburger";

const TableHeader = ({
  handleExportRows,
  downloadFullTablePDF,
  filterText,
  handleFilterChange,
  toggleFilterPopover,
  toggleDensity,
  density,
}) => {
  return (
    <div className="flex gap-8 h-20 items-center">
      <div className="flex mb-2">
        <button
          onClick={handleExportRows}
          className={`w-[186px] text-white flex justify-center items-center h-12 text-xl mr-5 font-bold rounded-[10px] border border-white hover:scale-105 active:scale-95 transition-transform duration-200 ease-in-out`}
        >
          Download CSV
        </button>
        <button
          onClick={downloadFullTablePDF}
          className={`w-[186px] text-white flex justify-center items-center h-12 text-xl font-bold rounded-[10px] border border-white hover:scale-105 active:scale-95 transition-transform duration-200 ease-in-out`}
        >
          Download PDF
        </button>
      </div>
      <div className="flex justify-between items-center min-w-flex-1 w-full">
        <div className="min-w-[300px] -mt-7">
          <small className="text-[#DBE0FD]">
            Please use the format YYYY-MM-DD for date searches (e.g.,
            2024-05-20)
          </small>
          <div className="relative">
            <span className="absolute inset-y-0 left-3 flex items-center text-gray-400">
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-4.35-4.35M10 18a8 8 0 100-16 8 8 0 000 16z"
                ></path>
              </svg>
            </span>
            <input
              type="text"
              placeholder="Search"
              value={filterText}
              onChange={handleFilterChange}
              // onChange={(e) => setFilterText(e.target.value)}
              className="w-full rounded-full pl-10 pr-4 py-[6px] text-sm text-[#888888] placeholder-[#888888] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
        <div className="flex gap-4 relative">
          <button
            className="w-8 h-8 bg-white rounded-[3px] flex justify-center items-center"
            onClick={toggleFilterPopover}
          >
            <img src="/icons/table/Filter.png" alt="filter" />
          </button>
          <button
            className="w-8 h-8 text-sm bg-white rounded-[3px] flex justify-center items-center"
            onClick={toggleDensity}
          >
            {density === "compact" ? (
              <img
                className="w-7 h-7"
                src="/icons/table/smallHamburger.png"
                alt="filter"
              />
            ) : density === "comfortable" ? (
              <img
                className="w-7 h-7"
                src="/icons/table/Menu.png"
                alt="filter"
              />
            ) : (
              <BigHamburger />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
