import React, { useEffect, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./css/Hero.css";
import PrimaryButton from "../../button/PrimaryButton";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const imgRef = useRef(null);
  const navigate = useNavigate();

  const updateIconPositions = () => {
    const img = imgRef.current;
    if (!img) return;

    const imgRect = img.getBoundingClientRect();

    // Image width and height
    const imgWidth = imgRect.width;
    const imgHeight = imgRect.height;

    // Calculate icon positions as percentages of the image size
    const icon1Pos = { x: imgWidth * 0.15, y: imgHeight * 1.1 }; // Adjusted for bottom-left
    const icon2Pos = { x: imgWidth * 0.53, y: imgHeight * 0.48 }; // Adjusted for center
    const icon3Pos = { x: imgWidth * 0.85, y: imgHeight * 0.99 }; // Adjusted for top-right

    // Set the calculated positions
    document.querySelector(
      ".icon-1"
    ).style.transform = `translate(${icon1Pos.x}px, ${icon1Pos.y}px)`;
    document.querySelector(
      ".icon-2"
    ).style.transform = `translate(${icon2Pos.x}px, ${icon2Pos.y}px)`;
    document.querySelector(
      ".icon-3"
    ).style.transform = `translate(${icon3Pos.x}px, ${icon3Pos.y}px)`;
  };

  useEffect(() => {
    // Initial position update
    updateIconPositions();

    // Update positions on window resize
    window.addEventListener("resize", updateIconPositions);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateIconPositions);
    };
  }, []);

  return (
    <section className="h-screen relative bg-primary flex flex-col items-center justify-start">
      {/* Background Line Image */}
      <div className="absolute bottom-0 w-full h-[70%] z-10">
        <img
          ref={imgRef}
          src="/icons/line.png"
          alt="Curved line"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Main Content */}
      <div className="flex flex-col absolute left-0 top-1/2 gap-7 px-24 z-10 transform -translate-y-1/2">
        <h1 className="text-6xl font-bold w-[480px] text-white">
          Unleash the <span className="text-nutural">Power of Charity</span>
        </h1>
        <p className="text-white">
          Join hands with ACT to supercharge your donations and fundraising.
        </p>
        <div className="flex gap-7">
          <PrimaryButton
            onClick={() => navigate("/contact-form")}
            label="Open account"
            backgroundColor="bg-nutural"
          />
          <PrimaryButton
            onClick={() => navigate("/contact-us")}
            label="Contact us"
            textColor="text-white"
            extraClass="border-2 border-white"
          />
        </div>
      </div>

      {/* Icons Positioned Dynamically */}
      <div className="absolute inset-0">
        {/* Icon 1 */}
        <div className="icon-1 absolute bg-white flex justify-center items-center w-[98px] h-[98px] rounded-full transform border-8 border-primary z-20">
          <div className="h-10 w-10 bg-violatee rotate-45 flex justify-center items-center">
            <img
              src="/icons/hand.png"
              alt="Icon 1"
              className="w-6 h-6 -rotate-45"
            />
          </div>
        </div>

        {/* Icon 2 */}
        <div className="icon-2 absolute bg-white w-[98px] h-[98px] flex justify-center items-center rounded-full transform border-8 border-primary z-20">
          <div className="h-10 relative w-10 flex justify-center items-center">
            <img src="/icons/Location.png" alt="Icon 2" className="w-12 h-12" />
            <img
              src="/icons/Heart.png"
              alt="Heart Icon"
              className="w-6 h-6 absolute top-0"
            />
          </div>
        </div>

        {/* Icon 3 */}
        <div className="icon-3 absolute bg-white w-[98px] h-[98px] flex justify-center items-center rounded-full transform z-20">
          <div className="h-10 w-10 bg-grn flex justify-center rounded-full items-center">
            <img
              src="/icons/BritishPound.png"
              alt="Icon 3"
              className="w-6 h-6"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
