import React from "react";
import letterhead from "../../assets/Letterhead.jpg";
import receiptImage from "../../assets/receiptBg.jpg";

const Receipt = ({ receipt }) => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="h-auto w-96 p-6 bg-white shadow-md rounded-lg">
        <img src={letterhead} alt="Letterhead" className="w-full mb-4" />
        <div className="relative">
          <img src={receiptImage} alt="Receipt" className="w-full mx-auto mb-4 rounded-lg" />
          <h1 className="absolute top-11 left-1/2 transform -translate-x-1/2 text-sm font-bold text-center">
            Donation Receipt
          </h1>
          <p className="absolute top-10 left-2 text-xs">
            <span>Date:</span> {receipt.date}
          </p>
          <p className="absolute top-10 right-5  text-xs">
            <span>Receipt No. </span> {receipt.id - 10000}
          </p>
          <p className="absolute top-16 left-10 text-xs">
            <span>Name:</span> {receipt.name}
          </p>
          <p className="absolute bottom-12 left-10 text-xs">
            <span>Amount:</span> £ {receipt.total}
          </p>
          <p className="absolute bottom-12 left-1/2 text-xs">
            <span>Signature A Adler</span>
          </p>
        </div>
        <p className="text-center text-lg font-semibold">Thank you</p>
      </div>
    </div>
  );
};

export default Receipt;
