import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useInternalTransferMutation } from "../store";
import { debounce } from "@mui/material";
import useToastSpinner from "../hooks/useToastSpinner";

const TransfarFunds = () => {
  const [id, setId] = useState("");
  const [toID, setToID] = useState(null);
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");

  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);
  const balance = useSelector((state) => state.user.balance);
  const { toastSpinner } = useToastSpinner();

  const payload = useMemo(() => ({ clientID: client?.ID, token }), [client, token]);

  const [internalTransfer] = useInternalTransferMutation();

  const handleIdBlur = useCallback(
    debounce((id) => {
      setToID(id);
    }, 300),
    []
  );

  const handleIdChange = (e) => {
    setToID(null);
    setId(e.target.value);
    handleIdBlur(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!toID) {
      toast.error("Recipient ID is required");
      return;
    }

    if (amount <= 0 || amount > balance) {
      toast.error("Invalid amount");
      return;
    }

    try {
      // Use toastSpinner for handling internalTransfer
      const res = await toastSpinner(
        internalTransfer({ toID, amount, note, ...payload }),
        "Processing transfer...", // Loading message
        "Transfer submited successfully.", // Success message
        "Failed to process the transfer." // Error message
      );

      if (res?.data) {
        setAmount("");
        setNote("");
        setId("");
        setToID(null);
      } else {
        // Handle specific error messages from the API
        toast.error(res.error?.data?.message || "Transfer failed.");
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <section>
      <div>
        <h2 className="text-5xl font-bold text-white mb-5">Transfer Funds</h2>
        <p className="text-white text-lg font-bold mb-7">
          Current Balance: <span className="font-semibold">£ {balance}</span>
        </p>
        <form className="bg-white py-7 px-5 text-[#252525]" onSubmit={handleSubmit}>
          <div className="w-8/12">
            {/* Pay out method */}
            <div>
              <label htmlFor="payOutMethod" className="block font-medium mb-3">
                Please enter the A.C.T Account ID
              </label>
              <input
                className="w-full rounded border border-borderColor3 p-3 focus:ring-blue-500 focus:border-blue-500"
                id="id"
                value={id}
                onChange={handleIdChange}
              />
            </div>
            {/* Pay out method */}
            <div>
              <label htmlFor="payOutMethod" className="block font-medium mb-3">
                Amount
              </label>
              <input
                className="w-full rounded border border-borderColor3 p-3 focus:ring-blue-500 focus:border-blue-500"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>

            {/* Notes */}
            <div className="mb-6">
              <label htmlFor="notes" className="block font-medium mb-3">
                Notes
              </label>
              <textarea
                id="note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                rows="4"
                className="w-full border border-borderColor3 rounded p-3 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter additional notes here..."
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-[200px] h-[50px] text-lg bg-primary text-white font-bold py-3 rounded hover:bg-blue-800 transition"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default TransfarFunds;
