import React, { useEffect, useState } from "react";
import { useFetchCharityQuery, useInternalTransferMutation } from "../store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/shared/Loader";
import useToastSpinner from "../hooks/useToastSpinner";

const DonateToCharity = () => {
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  // const [isSubmitting, setIsSubmitting] = useState(false);

  const token = useSelector((state) => state.user.token);
  const [charityName, setCharityName] = useState("");
  const [selectedCharity, setSelectedCharity] = useState("");
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const payload = { clientID: client?.ID, token };
  const balance = useSelector((state) => state.user.balance);
  const { toastSpinner } = useToastSpinner();

  const { data: charities } = useFetchCharityQuery(token);
  const [internalTransfer] = useInternalTransferMutation();

  const filteredCharities = charities?.data?.filter((charity) =>
    charity.Accounts_Name.toLowerCase().includes(charityName.toLowerCase())
  );

  useEffect(() => {
    const select = filteredCharities?.find((char) => char.Accounts_Name === charityName);
    setSelectedCharity(select || null);
  }, [charityName, filteredCharities]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCharity) {
      toast.error("Please select a charity");
      return;
    }

    if (amount > balance) {
      toast.error("Insufficient balance");
      return;
    }

    try {
      // setIsSubmitting(true);
      // Use toastSpinner for handling internalTransfer
      const res = await toastSpinner(
        internalTransfer({
          toID: selectedCharity.Accounts_ID,
          amount,
          note,
          ...payload,
        }),
        "Processing transfer...", // Loading message
        "Donation submitted successfuly.", // Success message
        "Failed to process the donation." // Error message
      );

      if (res?.data) {
        setAmount("");
        setNote("");
        setSelectedCharity(null);
      } else {
        // Additional error handling for API response
        toast.error(res.error?.data?.message || "Transfer failed.");
      }
    } catch (error) {
      console.error("Unexpected error during transfer:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <section>
      <div>
        <h2 className="text-5xl font-bold text-white mb-5">Donate to a Charity</h2>
        <p className="text-white text-lg font-bold mb-7">
          Current Balance: <span className="font-semibold">£ {balance}</span>
        </p>
        <form className="bg-white py-7 px-5 text-[#252525]" onSubmit={handleSubmit}>
          <div className="w-8/12">
            {/* Amount */}
            <div>
              <label htmlFor="charityName" className="block font-medium mb-3">
                Charity Name
              </label>
              <select
                id="charityName"
                className="w-full rounded border border-borderColor3 p-3 focus:ring-blue-500 focus:border-blue-500"
                value={charityName}
                onChange={(e) => setCharityName(e.target.value)}
              >
                <option value="" disabled>
                  Select a Charity
                </option>
                {charities?.data?.map((data) => (
                  <option key={data.Accounts_ID} value={data.Accounts_Name}>
                    {data.Accounts_Name}
                  </option>
                ))}
              </select>
            </div>

            {/* Pay out method */}
            <div>
              <label htmlFor="payOutMethod" className="block font-medium mb-3">
                Donation Amount
              </label>
              <input
                id="amount"
                className="w-full rounded border border-borderColor3 p-3 focus:ring-blue-500 focus:border-blue-500"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>

            {/* Notes */}
            <div className="mb-6">
              <label htmlFor="notes" className="block font-medium mb-3">
                Notes
              </label>
              <textarea
                id="note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                rows="4"
                className="w-full border border-borderColor3 rounded p-3 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter additional notes here..."
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-[200px] h-[50px] text-lg bg-primary text-white font-bold py-3 rounded hover:bg-blue-800 transition"
            >
              DONATE
            </button>
          </div>
        </form>
      </div>
    </section>
    // <section
    //   style={{
    //     height: "92vh",
    //     width: "100%",
    //     backgroundColor: "#f0f2f5",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}
    // >
    //   <Paper elevation={3} sx={{ p: 4, width: { xs: "100%", md: "50%" } }}>
    //     <Typography variant="h4" gutterBottom>
    //       Donate to a Charity
    //     </Typography>
    //     <Autocomplete
    //       options={filteredCharities || []}
    //       getOptionLabel={(option) => option.Accounts_Name}
    //       onChange={handleCharitySelect}
    //       renderInput={(params) => (
    //         <TextField
    //           {...params}
    //           label="Charity Name"
    //           variant="outlined"
    //           margin="normal"
    //           value={charityName}
    //           onChange={(e) => setCharityName(e.target.value)}
    //         />
    //       )}
    //       loading={isFetching}
    //       renderOption={(props, option) => (
    //         <li {...props} key={option.Accounts_ID}>
    //           {option.Accounts_Name}
    //         </li>
    //       )}
    //     />
    //     {selectedCharity && (
    //       <Box mt={2} mb={2}>
    //         <Typography variant="h5">
    //           {selectedCharity.Accounts_Name}
    //         </Typography>
    //         <Typography variant="body1">
    //           {selectedCharity.Accounts_Address}
    //         </Typography>
    //         <Typography variant="body2">
    //           {selectedCharity.Accounts_City}
    //         </Typography>
    //         <Typography variant="caption">
    //           {selectedCharity.Accounts_CharityNo}
    //         </Typography>
    //       </Box>
    //     )}
    //     <TextField
    //       fullWidth
    //       type="number"
    //       label="Donation Amount"
    //       value={amount}
    //       onChange={(e) => setAmount(e.target.value)}
    //       variant="outlined"
    //       margin="normal"
    //     />
    //     <TextField
    //       fullWidth
    //       type="text"
    //       label="Note"
    //       value={note}
    //       onChange={(e) => setNote(e.target.value)}
    //       variant="outlined"
    //       margin="normal"
    //     />
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       onClick={handleSubmit}
    //       fullWidth
    //       sx={{ mt: 2 }}
    //     >
    //       Donate
    //     </Button>
    //   </Paper>
    // </section>
  );
};

export default DonateToCharity;
